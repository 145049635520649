import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Spinner } from "react-bootstrap";
import { HelmetProvider } from 'react-helmet-async';

// Lazy load the components
const Home = lazy(() => import("./pages/home/Home"));
const Physiotherapist = lazy(() =>
  import("./pages/physiotherapist/Physiotherapist")
);
const HomeExerciseLibrary = lazy(() =>
  import("./pages/homeExerciseLibrary/HomeExerciseLibrary")
);
const Clinic = lazy(() => import("./pages/clinic/Clinic"));
const Patient = lazy(() => import("./pages/patient/Patient"));
const PatientEngagement = lazy(() =>
  import("./pages/patientEngagement/PatientEngagement")
);
const SecurityReliability = lazy(() =>
  import("./pages/securityReliability/SecurityReliability")
);
const AboutVirtueLife = lazy(() =>
  import("./pages/aboutVirtueLife/AboutVirtueLife")
);
const HEPFeatures = lazy(() => import("./pages/hepFeatures/HEPFeatures"));
const HealthcareSystems = lazy(() =>
  import("./pages/healthcareSystems/HealthcareSystems")
);
const VirtueLifeBadge = lazy(() =>
  import("./pages/virtueLifeBadge/VirtueLifeBadge")
);
const ContactUs = lazy(() => import("./pages/contactUs/ContactUs"));
const DeveloperInformation = lazy(() =>
  import("./pages/developerInformation/DeveloperInformation")
);
const Blog = lazy(() => import("./pages/blog/Blog"));
const BlogInnerPage = lazy(() => import("./pages/blogInnerPage/BlogInnerPage"));
const Pricing = lazy(() => import("./pages/pricing/Pricing"));
const Licence = lazy(() => import("./components/licence/Licence"));
const Terms = lazy(() => import("./components/licence/Terms"));
const Privacy = lazy(() => import("./components/licence/Privacy"));
const Refund = lazy(() => import("./components/licence/Refund"));

function App() {
  return (
    <HelmetProvider>

    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              style={{ color: "#FE6036", width: "3rem", height: "3rem" }}
            />
          </div>
        }
      >
        {" "}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/physiotherapist" element={<Physiotherapist />} />
          <Route path="/clinic" element={<Clinic />} />
          <Route path="/patient" element={<Patient />} />
          <Route
            path="/home-exercise-library"
            element={<HomeExerciseLibrary />}
          />
          <Route path="/patient-engagement" element={<PatientEngagement />} />
          <Route
            path="/security-reliability"
            element={<SecurityReliability />}
          />
          <Route path="/about-virtuelife" element={<AboutVirtueLife />} />
          <Route path="/hep-features" element={<HEPFeatures />} />
          <Route path="/healthcare-systems" element={<HealthcareSystems />} />
          <Route path="/virtuelife-badge" element={<VirtueLifeBadge />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/developer-information"
            element={<DeveloperInformation />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogInnerPage />} /> {/* Dynamic route */}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/end-user-license-agreement" element={<Licence />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
